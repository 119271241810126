<template>
  <div
    v-editable="blok"
    class="group flex w-full flex-col rounded-double border-2 border-transparent p-4 transition-colors hover:border-text aria-expanded:border-text"
    :class="{ 'pt-14': blok.icon }"
    @mouseenter="opened = true"
    @mouseleave="opened = false"
  >
    <nuxt-icon
      v-if="blok.icon"
      :name="blok.icon.toString()"
      class="z-10 -mt-11 mb-2 grid aspect-square w-14 place-items-center rounded-full bg-blue-dark text-2xl text-white dark:bg-blue [&>svg]:!mb-0"
    />
    <button
      :id="`accordeon-button-${blok._uid}`"
      type="button"
      class="accordeon-button relative flex flex-col text-start focus:outline-none"
      :aria-expanded="opened"
      :aria-controls="`accordeon-content-${blok._uid}`"
      :aria-labelledby="`${blok._uid}-label`"
      @click="opened = true"
      @focus="opened = true"
      @blur="opened = false"
    >
      <h3 :id="`${blok._uid}-label`">
        <div class="inline-images prose prose-xl mt-2 text-balance leading-tight dark:prose-invert" v-html="headline" />
      </h3>
      <div
        class="expand-icon -mb-4 -mt-1 ml-auto inline-flex items-center justify-center rounded-full tablet:mt-1 desktop:-mt-1"
        role="presentation"
      >
        <nuxt-icon class="grid place-items-center text-[2rem] [&>svg]:!mb-0" name="expand" filled />
      </div>
    </button>
    <hr class="w-1/3 rounded-double border-2 border-text" />
    <div
      :id="`accordeon-content-${blok._uid}`"
      ref="accordeonContentEl"
      class="max-h-[--content-height] overflow-y-hidden transition-[max-height,opacity] aria-hidden:max-h-0 aria-hidden:opacity-0"
      role="region"
      :aria-hidden="!opened"
      :aria-labelledby="`accordeon-button-${blok._uid}`"
      :style="`--content-height: ${contentHeight}`"
    >
      <div class="inline-images prose prose-lg pt-4 dark:prose-invert" v-html="content" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { CardAccordeonStoryblok } from "~/storyblok-component-types"

const props = defineProps<{ blok: CardAccordeonStoryblok; }>()

const opened = ref(false)
const headline = renderRTF(props.blok?.headline)
const content = renderRTF(props.blok?.content)

const accordeonContentEl = ref<HTMLElement>()
const contentHeight = ref("40rem")

function updateContentHeight() {
  if (accordeonContentEl.value) contentHeight.value = `${accordeonContentEl.value.scrollHeight}px`
}

onMounted(() => {
  updateContentHeight()
  window.addEventListener("resize", updateContentHeight, { passive: true })
})

onUnmounted(() => {
  window.removeEventListener("resize", updateContentHeight)
})
</script>

<style lang="scss">
.accordeon-button {
  svg {
    @apply transition-[transform];

    path {
      @apply transition-transform;
    }
  }

  &:is(:hover, :active, :focus) svg {
    path {
      @apply translate-y-0.5;
    }
  }

  .expand-icon {
    @apply transition-opacity duration-200;
  }

  &[aria-expanded="true"] .expand-icon {
    @apply opacity-0;

    svg path {
      @apply translate-y-2 ease-in;
    }
  }
}
</style>
